/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //Lightbox init
        if( $('.lightbox').length > 0 ){
          var lightbox = $('.flexible-content .lightbox').simpleLightbox();
        }

        //Backtop button
        $('.backtop').on('click', function(e){
          e.preventDefault();
          $('html, body').animate({
            scrollTop: 0
          }, 500);
        });

        if( $('.seealso').length > 0 && $(window).width() >= 1280 ){
          var defaultSeeAlsoTop = $('.seealso').position().top;
          var blockOffsetTop = $('.seealso').offset().top;
          var blockHeight = $('.seealso').height();
          var footerTop = $('.backtop').offset().top;
          var topPosition = $(document).scrollTop() - (blockHeight + $('footer.content-info').height() + $('a.backtop').height() + 50 );

          $(window).on('scroll', function(){
            var scroll = $(window).scrollTop();
            if( scroll+blockHeight >= footerTop ){
              $('.seealso').addClass('absolute');
              $('.seealso').css('top',topPosition);
            }else{
              $('.seealso').removeClass('absolute');
              $('.seealso').css('top','27px');
            }

            if (scroll >= 520 ){
              $('.seealso').addClass('fixed');
            }else{
              $('.seealso').removeClass('fixed');
              $('.seealso').css('top','-78px');
            }
          });
        }

        //Submenu toggle on mobile
        if( $(window).width() <= 1280 ){
          $('#main-nav ul li a').each(function(){
            if( $(this).parent().find('.sub-menu').length > 0 ){
              $(this).on('click', function(e){
                e.preventDefault();
                $(this).parent().find('.sub-menu').slideToggle();
              });
            }
          });
        }

        //Fix carousel header responsive
        $(window).on('load resize', function(){
          $('.carousel-pages .carousel-item a, .carousel-home .carousel-item a').each(function(){
            $(this).find('.mask').css('height',$(this).find('img').height());
          });

          if( $(window).width() <= 768 ){
            $('#home .mask').width($('#home .carousel-item.active img').width());
            $('#home .mask').height($('#home .carousel-item.active img').height()+4);
            $('#carousel-header .mask').width($('#carousel-header .carousel-item.active img').width());
            $('#carousel-header .mask').height($('#carousel-header .carousel-item.active img').height()+4);
          }
          $('#home .intro, #carousel-header .intro, #home .carousel-indicators').css('max-width',$(window).width());
        });

      },
      finalize: function() {
        $(window).on('load resize', function(event) {
          $('header.banner').height($('.carousel').height());
          $('.wrap').css({minHeight: ($(window).height() - $("header.banner").outerHeight(true) - $('footer.content-info').outerHeight(true) - 40 )});

          var windowHeight = $(window).height();
          var footerTop = $('footer.content-info').offset().top;

          if( footerTop < windowHeight ){
            $('footer.content-info').height(windowHeight - footerTop - 60 );
          }

          if( $(window).width() >= 1280 ){
            if( $('header.banner .carousel .intro').outerHeight(true) <= 505 ){
              $('.flexible-content').css('marginTop','0');
            }
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        if( $(window).width() >= 1280 ){
          $('#home').carousel({
            keyboard: true,
            interval: 5000
          });
        }else{
          $('#home').carousel({
            keyboard: true,
            interval: false
          });
        }

        $('#home-carousel').carousel({
          keyboard: false,
          interval: false,
        });

        $('#home-carousel').on('mouseenter', function(){
          $('#home').carousel('pause');
        });

        $('#home-carousel').on('mouseout', function(){
          $('#home').carousel('cycle');
        });


        $('#home').on('slide.bs.carousel', function (direction) {
          var current_index = $(direction.relatedTarget).index();
          $('#home-carousel').carousel(current_index);

          $('.carousel-home .carousel-item a').each(function(){
            $(this).find('.mask').css('height',$(this).find('img').height());
          });
        });

        //Fix slider arrows on mobile scroll
        if( $(window).width() <= 1280 ){
          var defaultSliderTop = $('#home-carousel').position().top;
          var defaultSliderHeight = $('#home-carousel').height();
          var sliderOffsetTop = $('#home-carousel').offset().top;
          $(window).on('scroll', function(){
            var scroll = $(window).scrollTop();

            if( scroll >= sliderOffsetTop ){
              $('#home-carousel .carousel-pag').addClass('fixed');
            }
            if( scroll > (defaultSliderHeight+sliderOffsetTop) || scroll < sliderOffsetTop ){
              $('#home-carousel .carousel-pag').removeClass('fixed');
            }
          });
        }

        //Fix home responsive carousels
        $(window).on('load resize', function(){
          if( $(window).width() <= 768 ){
            $('#home .carousel-indicators').css('top',$('#home .carousel-item.active img').height()+10);
          }else{
            $('#home .carousel-indicators').css('top','490px');
          }
          $('#home .intro, #home .carousel-indicators').css('max-width',$(window).width());
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_contact': {
      init: function() {
        //Autosize
        autosize($('textarea'));


        //Add marker
        function add_marker( $marker, map ) {
          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          var markerIconVersion;
          //Retina marker icon
          if( window.devicePixelRatio > 1.5 ){
            markerIconVersion = $marker.attr('data-icon2');
          }else{
            markerIconVersion = $marker.attr('data-icon');
          }
          var markerIcon = new google.maps.MarkerImage(markerIconVersion, null, null, null, new google.maps.Size(32,32));
          // create marker
          var marker = new google.maps.Marker({
            position    : latlng,
            map         : map,
            icon        : markerIconVersion
          });
          // Règle spécifique pour éviter un bug sur certaines version de l'API
          if (window.devicePixelRatio > 1.5) {
            marker.setOptions({ optimized: false });
          }
          // add to array
          map.markers.push( marker );
          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() ){
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content     : $marker.html()
            });
            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
            });
          }
        }

        //Auto center map and show all markers
        function center_map( map ) {
          // vars
          var bounds = new google.maps.LatLngBounds();
          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });
          // only 1 marker?
          if( map.markers.length === 1 ){
            // set center of map
            map.setCenter( bounds.getCenter() );
            if( $(window).width() <= 480 ){
              map.setZoom( 14 );
            }else{
              map.setZoom( 15 );
            }
          }
          else{
            // fit to bounds
            map.fitBounds( bounds );
          }
        }

        //Create new map
        function new_map( $el ) {
          // var
          var $markers = $el.find('.marker');
          // vars
          var styles = [{stylers:[{hue:'#00303b'},{saturation:-70},{lightness:1},{gamma:1}]}];
          var args;
          if( $(window).width() >= 960 ){
            args = {
              zoom        : 12,
              center      : new google.maps.LatLng(0, 0),
              mapTypeId   : 'Styled',
              scrollwheel: false,
              draggable: true,
              disableDefaultUI: false
            };
          }else{
            args = {
              zoom        : 10,
              center      : new google.maps.LatLng(0, 0),
              mapTypeId   : 'Styled',
              scrollwheel: false,
              draggable: false,
              disableDefaultUI: false,
              disableDoubleClickZoom: true
            };
          }
          // create map
          var map = new google.maps.Map( $el[0], args);
          var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
          map.mapTypes.set('Styled', styledMapType);
          // add a markers reference
          map.markers = [];
          // add markers
          $markers.each(function(){
            add_marker( $(this), map );
          });
          // center map
          center_map( map );
          // return
          return map;
        }

        //Init map
        var map = null;
        $('.map').each(function(){
          // create map
          map = new_map( $(this) );
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
